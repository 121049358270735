import "jspdf-autotable"

import {
  Form,
  Badge,
  Button,
  Col,
  Modal,
  Row,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap"
import React, { Component } from "react"
import { farmActions, orderActions, usersActions } from "../../_actions"
import { ORDER_HARVEST_STATUS } from "../../_constants/order.constants.js"

import { Link } from "react-router-dom"
import Pagination from "react-js-pagination"
import Sidebar from "../../components/sidebar"
import { HarvestList } from "../../components/harvest-list"
import _, { add } from "lodash"
import { connect } from "react-redux"
import moment from "moment-timezone"
import { obj2queryString, queryString2Obj } from "../../util/query-string"
import { SubscriptionInfo } from "../../util/inventory"
import {
  orderShippedBuyList,
  orderNonShippedBuyList,
  orderDistributions,
  orderDetailsTable,
  orderTimeString,
  getDeliveryAddressFromOrder,
} from "../../util/order"
import USPSBoxForm from "../../components/uspsBoxForm"
import USPSLoginModal from "../../components/USPSLoginModal"
const debug = require("debug")("order")

const HARVEST_STATUSES = [
  "New Order",
  "In Harvest List",
  "Fulfilled",
  "Out of Stock",
]

const DEFAULT_ORDER_PAGINATION_COUNT = 10

const sumOrderAndDelivery = (productlist, user) => {
  if (productlist && productlist.length > 0) {
    let countPrice = 0
    for (let i = 0; i < productlist.length; ++i) {
      if (productlist[i].farmUserId === localStorage.getItem("userId")) {
        let num = productlist[i].totalPayPrice
        countPrice = num.toFixed(2)
      }
    }
    return countPrice
  }
}

class Order extends Component {
  constructor(props) {
    super(props)

    let filterHarvest = ORDER_HARVEST_STATUS.ALL

    const params = queryString2Obj({ queryString: window.location.search })
    if (
      params &&
      Object.values(ORDER_HARVEST_STATUS).includes(params.filterHarvest)
    ) {
      filterHarvest = params.filterHarvest
    }

    this.state = {
      orderInfo: [],
      ItemList: [],
      reId: [],
      excelFile: "",
      ItemId: [],
      itemId: [],
      multiValue: [],
      multiItemValue: [],
      reasonErMsg: "",
      show: false,
      farmName: "",
      farmId: "",
      farmStripeId: "",
      showInvoice: false,
      messagePopup: 0,
      alertPopup: 0,
      emailPopup: 0,
      lat: 0,
      long: "",
      count: DEFAULT_ORDER_PAGINATION_COUNT,
      totalCount: 0,
      dataCount: 0,
      filterHarvest,
      pageNo: 1,
      orderInfo1: [],
      profilePicPreview: "",
      userId: "",
      hasOrderInfo: false,

      // USPS Boxes
      showUSPSModal: false,
      isCreatingLabels: false,
      boxList: [
        {
          id: 0,
          selectedRate: null,
          boxLength: "",
          boxWidth: "",
          boxHeight: "",
          boxPounds: "",
          boxOunces: "",
        },
      ],
      ratesByBox: {},
      boxAddress: {
        from: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
        },
        to: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          postalCode: "",
        },
      },
      boxAddressError: [],
      boxFormError: [],
      uspsLabelOrderInfo: [],
      uspsErrors: [],

      // USPS Label
      showUSPSLabelModal: false,
      uspsLabelsPrinted: false,
      uspsCreateLabelError: false,

      showUSPSLoginModal: false,

      isUSPSLoggedIn: false,
      destinationEntryFacilityAddress: null,
      destinationEntryFacilityType: null,
      destinationEntryFacilities: [],
      selectedFacility: null,

      loadingPickupLocations: false, // Added loading state for pickup locations
    }
  }

  handleRateSelect = (rate) => {
    this.setState({ selectedRate: rate, uspsBoxRateConfirm: true })
    console.log("Selected Rate:", rate)
  }

  componentDidMount() {
    this.getUserInfo()
  }

  checkUSPSAuthorizationOrLogin = () => {
    const params = new URLSearchParams(window.location.search)
    const authorizationCode = params.get("authorization_code")
    const farmId = params.get("state")

    if (authorizationCode && farmId) {
      this.handleUSPSAuthorization(authorizationCode, farmId)
    } else {
      let { farmId } = this.state
      this.checkUSPSLoginStatus(farmId)
    }
  }

  getFarmDetails = () => {
    let { userId } = this.state

    if (!userId) {
      return
    }

    let self = this
    this.setState({ loader: true, errors: "" })

    let params = `?userId=${userId}&farmerId=${userId}`

    let { dispatch } = this.props
    dispatch(farmActions.getFarmDetails(params))
      .then((data) => {
        if (!_.isEmpty(data)) {
          let farmInfo = data.responseData.result
          self.setState({ loader: false })

          if (data.statusCode === 1) {
            self.setState({
              farmLogo: farmInfo.length > 0 ? farmInfo[0].farmLogo : "",
              farmName: farmInfo.length > 0 ? farmInfo[0].farmName : "",
              farmId: farmInfo.length > 0 ? farmInfo[0]["_id"] : "",
              farmStripeId: farmInfo.length > 0 ? farmInfo[0].accountId : "",
              addressLine1:
                farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                  ? farmInfo[0].farmAddress[0].addressLine1
                  : "",
              addressLine2:
                farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                  ? farmInfo[0].farmAddress[0].addressLine2
                  : "",
              city:
                farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                  ? farmInfo[0].farmAddress[0].city
                  : "",
              state:
                farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                  ? farmInfo[0].farmAddress[0].state
                  : "",
              postalCode:
                farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                  ? farmInfo[0].farmAddress[0].postalCode
                  : "",
              boxAddress: {
                from: {
                  addressLine1:
                    farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                      ? farmInfo[0].farmAddress[0].addressLine1
                      : "",
                  addressLine2:
                    farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                      ? farmInfo[0].farmAddress[0].addressLine2
                      : "",
                  city:
                    farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                      ? farmInfo[0].farmAddress[0].city
                      : "",
                  state:
                    farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                      ? farmInfo[0].farmAddress[0].state
                      : "",
                  postalCode:
                    farmInfo.length > 0 && !_.isEmpty(farmInfo[0].farmAddress)
                      ? farmInfo[0].farmAddress[0].postalCode
                      : "",
                },
              },
            })
          } else {
            self.setState({ errors: data.error.responseMessage })
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.checkUSPSAuthorizationOrLogin()
      })
  }

  getOrderExcel = (filterHarvest) => {
    let self = this

    // when the list reflects the harvest status, we can
    // just lean on that. but since the list status can be
    // different than what the button offers, we must pass
    // in a value
    let { userId } = this.state
    const queryString = obj2queryString({
      userId,
      filterHarvest,
    })

    let params = `?${queryString}`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderExcel(params)).then((data) => {
      let inventoryData = data

      self.setState({ loader: false })

      if (inventoryData.statusCode === 1) {
        window.open(data.responseData.file)
      } else {
        this.setState({ errors: inventoryData.error.responseMessage })
      }
    })
  }

  // getAllOrderExcel = () => {
  //   let self = this;

  //   let { userId } = this.state;
  //   let params = `?userId=${userId}`;

  //   let { dispatch } = this.props;
  //   dispatch(orderActions.getAllOrderExcel(params)).then((data) => {
  //     let inventoryData = data;

  //     self.setState({ loader: false });

  //     if (inventoryData.statusCode === 1) {
  //       window.open(data.responseData.file);
  //     } else {
  //       this.setState({ errors: inventoryData.error.responseMessage });
  //     }
  //   });
  // };

  sumOrder = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === localStorage.getItem("userId")) {
          countPrice = product[i].totalPrice
        }
      }
      let price = countPrice.toFixed(2)
      return price
    }
  }

  sumDelivery = (product) => {
    if (!_.isEmpty(product)) {
      let countPrice = 0
      for (let i = 0; i < product.length; i++) {
        if (product[i].farmUserId === localStorage.getItem("userId")) {
          countPrice = product[i].deliveryFees
        }
      }

      return countPrice
    }
  }

  getUserInfo = () => {
    this.setState({ errors: "", redirect: false, loader: false })

    let { dispatch } = this.props
    dispatch(usersActions.getUserInfo()).then((data) => {
      this.setState({ loader: false })

      if (data.statusCode === 1) {
        let userInfo = data.responseData.userProfile
        localStorage.setItem("userId", userInfo._id)

        this.setState({
          userId: userInfo._id,
          userInfo: userInfo,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phone: userInfo.phone,
          email: userInfo.email,
          address: userInfo.address,
          addressLine1:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine1
                ? userInfo.address[0].addressLine1
                : ""
              : "",
          addressLine2:
            userInfo.address.length > 0
              ? userInfo.address[0].addressLine2
                ? userInfo.address[0].addressLine2
                : ""
              : "",
          state:
            userInfo.address.length > 0
              ? userInfo.address[0].state
                ? userInfo.address[0].state
                : ""
              : "",
          postalCode:
            userInfo.address.length > 0
              ? userInfo.address[0].postalCode
                ? userInfo.address[0].postalCode
                : ""
              : "",
          city:
            userInfo.address.length > 0
              ? userInfo.address[0].city
                ? userInfo.address[0].city
                : ""
              : "",
          addressId:
            userInfo.address.length > 0
              ? userInfo.address[0]._id
                ? userInfo.address[0]._id
                : ""
              : "",
          minimumOrderValue: userInfo.minimumOrderValue,
          profilePic: userInfo.profileImage,
          profilePicPreview: userInfo.profileImage,
          certificate: userInfo.certificateImage,
          certificatePreview: userInfo.certificateImage,
          insuranceDoc: userInfo.insuranceImage,
          insurancePreview: userInfo.insuranceImage,
          radius: userInfo.radius,
          emailPopup: userInfo.notificationEmail,
          messagePopup: userInfo.notificationTextMassage,
          alertPopup: userInfo.notificationAppAlert,
          lat: userInfo.geoLocation[0],
          long: userInfo.geoLocation[1],
        })

        this.getOrderList()
        this.getFarmDetails()
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  handlePageChange = (pageNo) => {
    var self = this
    this.setState({ loader: true, pageNo: pageNo, count: 10 }, () => {
      self.getOrderList()
    })
  }

  getOrderList = () => {
    let { search, userId, filterHarvest, pageNo, count } = this.state

    if (!userId) {
      return
    }

    this.setState({ redirect: false, loader: true, errors: "" })
    const queryString = obj2queryString({
      search,
      userId,
      count,
      pageNo: Math.max(pageNo - 1, 0), // pagination on the server is 0 index, here on the pagination indicator it is 1 index
      filterHarvest,
    })
    let params = `?${queryString}&search=`

    let { dispatch } = this.props
    dispatch(orderActions.getOrderListByFarmer(params)).then((data) => {
      this.setState({
        loader: false,
        hasOrderInfo: true,
      })

      if (data.statusCode === 1) {
        const { responseData } = data
        const { orderList, count, total } = responseData
        this.setState({
          orderInfo: orderList,
          totalCount: total,
          dataCount: count,
        })

        // const hiddenLoadings = document.querySelectorAll('.hidden-loading');
        // hiddenLoadings.forEach(hiddenLoading => {
        //   hiddenLoading.classList.remove('hidden-loading');
        // });
      } else {
        this.setState({ errors: data.error.responseMessage })
      }
    })
  }

  checkUSPSAuthorization = () => {
    const params = new URLSearchParams(window.location.search)
    const authorizationCode = params.get("authorization_code")
    const farmId = params.get("state")

    if (authorizationCode && farmId) {
      this.handleUSPSAuthorization(authorizationCode, farmId)
    }
  }

  handleUSPSAuthorization = (authorizationCode, farmId) => {
    const { dispatch } = this.props
    dispatch(farmActions.getOAuthToken({ authorizationCode, farmId }))
      .then((serverResponseData) => {
        this.checkUSPSLoginStatus(farmId)
      })
      .catch((error) => {
        console.error("Error creating USPS tokens:", error.message)
      })
  }

  checkUSPSLoginStatus = (farmId) => {
    const { dispatch } = this.props
    dispatch(farmActions.USPSIsLoggedIn({ farmId }))
      .then((isLoggedIn) => {
        this.setState({ isUSPSLoggedIn: isLoggedIn.responseData.loggedIn })
      })
      .catch((error) => {
        console.error("Error checking USPS login status:", error)
        this.setState({ isUSPSLoggedIn: false })
      })
  }

  handleFilter = (filterHarvest) => {
    let count = DEFAULT_ORDER_PAGINATION_COUNT
    // the harvest list should not be paginated
    if (filterHarvest === ORDER_HARVEST_STATUS.HARVEST) {
      count = undefined
    }

    this.setState(
      {
        loader: true,
        reasonErMsg: "",
        filterHarvest,
        count,
        pageNo: 1,
      },
      () => this.getOrderList()
    )
  }

  handleClose = () => {
    this.setState({ show: false, showInvoice: false })
  }

  ConvertDate = (created) => {
    const created_date = new Date(created)

    let data = moment(created_date)
      .tz("America/New_York")
      .format("dddd MMMM Do YYYY, h:mm:ss a")

    return data
  }

  ChangeHarvestStatus = (id, harvestStatus) => {
    const { userId } = this.state
    let obj = {
      orderId: id,
      harvestStatus,
      userId,
    }

    let { dispatch } = this.props
    this.setState({ loader: true, show: false })

    dispatch(orderActions.changeHarvestStatusByFarmer(obj)).then((data) => {
      if (data.statusCode === 1) {
        this.getOrderList()
      } else {
        this.setState({ loader: false })
      }
    })
  }

  handleShow = (item) => {
    this.setState({ show: true, ItemList: item })
  }

  OrderBuyMp = (ItemList, itemColl, includeStatus = true) => {
    return (
      !_.isEmpty(ItemList) &&
      ItemList.map((val, i) => (
        <tr key={`order-buy-mp-${i}`}>
          <td>
            <p>
              {val.productQuantity} {val.productName}
            </p>
            <SubscriptionInfo inventoryItem={val} />
          </td>
          <td>{val.variety ? val.variety : ""}</td>

          <td>
            <span>
              ${val.productPrice} *{" "}
              {val.productQuantity ? val.productQuantity : ""} {val.unit}
            </span>
          </td>
          {includeStatus && (
            <td>
              <span className="inlinetext-align">
                <span className="inabs">
                  <input
                    type="checkbox"
                    indeterminate="true"
                    value={val.productId}
                    onChange={(e) => {
                      this.handleItemMultiSelect(
                        e,
                        val.productId && val.productId,
                        ItemList,
                        itemColl
                      )
                    }}
                  />
                </span>
                <span className="cm-aligntext">
                  {parseInt(val.itemStatus) === 1
                    ? "In Harvest List"
                    : parseInt(val.itemStatus) === 2
                    ? "Fulfilled"
                    : parseInt(val.itemStatus) === 3
                    ? "Out of Stock"
                    : "New Order"}
                </span>
              </span>
            </td>
          )}
          <td>
            {val.image && (
              <img alt="" src={val.image} height="40px" width="40px" />
            )}
          </td>
        </tr>
      ))
    )
  }

  OrderMp = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === localStorage.getItem("userId"))
        .map((val, i) => {
          return <>{this.OrderBuyMp(val.productList, ItemList, false)}</>
        })
    )
  }

  productName1 = (productName) => {
    return (
      !_.isEmpty(productName) &&
      productName.map((val, i) => (
        <>
          {val.productName} {val.variety ? "," + val.variety : ""} <br />
        </>
      ))
    )
  }

  productQuantity1 = (productName) => {
    return (
      !_.isEmpty(productName) &&
      productName.map((val, i) => (
        <>
          {val.productQuantity
            ? val.productQuantity + " " + (val.unit ? val.unit : "")
            : ""}
          <br />
        </>
      ))
    )
  }

  OrderHarvest1 = (ItemList) => {
    let Json =
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            {this.productName1(val.productList)} <br />
          </>
        ))

    return Json
  }

  OrderHarvest2 = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            {this.productQuantity1(val.productList)} <br />
          </>
        ))
    )
  }

  OrderHarvest3 = (ItemList) => {
    return (
      !_.isEmpty(ItemList.buyList) &&
      ItemList.buyList
        .filter((val) => val.farmUserId === this.state.userId)
        .map((val, i) => (
          <>
            $ {val.totalPrice}
            <br />
          </>
        ))
    )
  }

  handleValidate = () => {
    let validate = true

    if (
      this.state.multiValue === "" ||
      this.state.multiValue.length < 0 ||
      this.state.multiValue.length === 0 ||
      this.state.multiValue === undefined
    ) {
      validate = false
      this.setState({
        reasonErMsg: "Please select check box.",
      })
    } else {
      this.setState({
        reasonErMsg: "",
      })
    }

    return validate
  }

  handleMultiSelect = (e, val) => {
    const { checked } = e.target
    let { multiValue } = this.state

    this.setState({ reasonErMsg: "" })

    multiValue =
      typeof this.state.multiValue === "object" ? this.state.multiValue : []

    if (this.state.reId.includes(val)) {
      for (var i = 0; i < this.state.reId.length; i++) {
        if (this.state.reId[i] === val) {
          this.state.reId.splice(i, 1)
        }
      }
    } else {
      this.state.reId.push(val)
    }

    if (checked && !_.includes(multiValue, val)) {
      multiValue.push(val)
    }
    if (!checked) {
      _.pull(multiValue, val)
    }

    this.setState({
      multiValue,
      // When the multiValue changes, reset the USPS states
      boxList: [
        {
          id: 0,
          selectedRate: null,
          boxLength: "",
          boxHeight: "",
          boxPounds: "",
          boxOunces: "",
        },
      ],
      uspsBoxRates: [],
      boxFormError: [],
      ratesByBox: {},
      uspsBoxRateConfirm: false,
    })
  }

  handleAction = (e) => {
    const { userId } = this.state
    let unique = [...new Set(this.state.multiValue)]
    let params = {
      orderId: unique.toString(),
      harvestStatus: e,
      userId,
    }

    if (this.handleValidate()) {
      let { dispatch } = this.props
      this.setState({ loader: true })

      dispatch(orderActions.changeOrderStatusByFarmer(params)).then((data) => {
        if (data.statusCode === 1) {
          this.setState({ multiValue: [] })
          this.getOrderList()
        } else {
          this.setState({
            loader: false,
            reId: [],
            multiValue: [],
            reasonErMsg: "",
            errors: data.error.responseMessage,
          })
        }
      })
    }
  }

  bufferToBase64(bufferData) {
    return new Promise((resolve, reject) => {
      try {
        const byteArray = new Uint8Array(bufferData)
        const base64String = btoa(
          byteArray.reduce((data, byte) => data + String.fromCharCode(byte), "")
        )
        resolve(base64String)
      } catch (error) {
        reject(error)
      }
    })
  }

  decodeBase64Pdf(encodedText, renderImages = false) {
    try {
      console.log(encodedText)
      const base64Binary = atob(encodedText) // Decode the text to get the actual base64 encoded PDF
      const decodedData = Buffer.from(base64Binary, "base64") // Decode the base64 encoded PDF
      console.log(decodedData)
      const blob = new Blob([decodedData], { type: "application/pdf" })

      const url = URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url

      const dateNow = moment().format("MM-DD-YYYY-hh:mm:ss")
      a.download = `usps-label-${dateNow}.pdf`
      a.click()
      if (renderImages) {
        const iframe = document.createElement("iframe")
        const newDiv = document.createElement("div")
        newDiv.id = "usps-shipping-labels"
        document.getElementById("usps_labels").appendChild(newDiv)

        iframe.src = url
        iframe.style.width = "100%"
        iframe.style.height = "100%"

        document.getElementById("usps-shipping-labels").appendChild(iframe)
      }
    } catch (error) {
      console.error("Error decoding base64 PDF:", error)
    }
  }

  handleUSPSAction = (e) => {
    const { userId } = this.state
    if (!this.state.isUSPSLoggedIn) {
      this.setState({ showUSPSLoginModal: true })
      return
    }
    const {
      orderInfo,
      multiValue,
      addressLine1,
      addressLine2,
      city,
      state,
      postalCode,
      boxAddress,
    } = this.state
    const checkedOrders = orderInfo.filter((order) =>
      multiValue.includes(order._id)
    )

    if (checkedOrders.length > 0) {
      const firstOrder = checkedOrders[0]

      const deliveryAddress = getDeliveryAddressFromOrder(
        firstOrder,
        localStorage.getItem("userId")
      )

      this.setState({
        showUSPSModal: true,
        uspsLabelOrderInfo: checkedOrders,
        boxAddress: {
          from: boxAddress.from,
          to: deliveryAddress,
        },
      })
    }
  }

  isUSPSLoggedIn = () => {
    const { dispatch, farmId } = this.props
    dispatch(farmActions.USPSIsLoggedIn(farmId))
      .then((isLoggedIn) => {
        if (isLoggedIn) {
          console.log("User is logged in to USPS")
        } else {
          console.log("User is not logged in to USPS")
        }
      })
      .catch((error) => {
        console.error("Error checking USPS login status:", error)
      })
  }

  handleCancelLabel = (trackingNumber) => {
    const { dispatch } = this.props
    const { farmId, ItemList } = this.state

    const params = {
      orderNumber: ItemList._id,
      farmId,
      trackingNumber,
    }

    const confirmed = window.confirm(
      "Are you sure you want to cancel this label?"
    )

    if (confirmed) {
      dispatch(farmActions.cancelLabel(params)).then((data) => {
        if (data.statusCode === 1) {
          try {
            // Update the ItemList to remove the canceled label
            const updatedItemList = { ...ItemList }
            updatedItemList.buyList = updatedItemList.buyList.map((buyItem) => {
              return {
                ...buyItem,
                trackingInfo: buyItem.trackingInfo.filter(
                  (label) => label.trackingNumber !== trackingNumber
                ),
              }
            })

            this.setState({ ItemList: updatedItemList }, () => {
              this.forceUpdate() // Force a re-render if necessary
            })

            // Refresh the order list to reflect the changes
            this.getOrderList()
          } catch (error) {
            console.log(error)
          }
        } else {
          this.setState({ errors: data.error.responseMessage })
        }
      })
    }
  }

  handleVerifyAddress = () => {
    const addressTypes = ["from", "to"]
    addressTypes.forEach((addressType) => {
      let { farmId, boxAddress } = this.state

      let address = boxAddress[addressType]
      console.log(address)

      let params = {
        address1: address.addressLine1,
        address2: address.addressLine2,
        city: address.city,
        state: address.state,
        zip5: address.postalCode,
        farmId,
      }

      let { dispatch } = this.props

      dispatch(farmActions.verifyAddress(params)).then((data) => {
        if (data.statusCode === 1) {
          let suggestedAddress = data.responseData.address
          let suggestedAddressString = `${suggestedAddress.streetAddress} ${
            suggestedAddress.secondaryAddress
              ? suggestedAddress.secondaryAddress
              : ""
          }, ${suggestedAddress.city}, ${suggestedAddress.state} ${
            suggestedAddress.ZIPCode
          }`

          let currentAddressString = `${address.addressLine1} ${
            address.addressLine2 ? address.addressLine2 : ""
          }, ${address.city}, ${address.state} ${address.postalCode}`

          if (
            suggestedAddressString.replace(/\s/g, "").toLowerCase() !==
            currentAddressString.replace(/\s/g, "").toLowerCase()
          ) {
            if (
              window.confirm(
                `USPS suggests changing the address to:\n${suggestedAddressString}\n\nDo you want to apply this suggestion?`
              )
            ) {
              boxAddress[addressType] = {
                addressLine1: suggestedAddress.streetAddress,
                addressLine2: suggestedAddress.secondaryAddress
                  ? suggestedAddress.secondaryAddress
                  : "",
                city: suggestedAddress.city,
                state: suggestedAddress.state,
                postalCode: suggestedAddress.ZIPCode,
              }
              this.setState({
                boxAddress,
                showUSPSAddressModal: false,
                showUSPSModal: true,
                uspsErrors: {
                  ...this.state.uspsErrors,
                  [`${addressType}AddressError`]: "",
                },
              })
            }
          } else {
            this.setState({
              uspsErrors: {
                ...this.state.uspsErrors,
                [`${addressType}AddressError`]: "",
              },
            })
          }
        } else {
          this.setState({
            loader: false,
            uspsErrors: {
              ...this.state.uspsErrors,
              [`${addressType}AddressError`]:
                "Unable to verify " +
                addressType +
                " address with USPS. Please ensure the address is valid and that you are using the two-letter state abbreviation before continuing.",
            },
          })
        }
      })
    })
  }

  handleUSPSValidate = () => {
    const { boxList, boxAddress } = this.state
    let errors = []
    let zipValidate = false
    let boxAddressErrors = {
      from: {},
      to: {},
    }

    boxList.map((box, i) => {
      const combinedWeight =
        parseFloat(box.boxPounds * 16) + parseFloat(box.boxOunces)

      if (
        _.isEmpty(box.boxLength) ||
        _.isEmpty(box.boxWidth) ||
        _.isEmpty(box.boxHeight) ||
        _.isEmpty(box.boxPounds) ||
        _.isEmpty(box.boxOunces)
      ) {
        errors.push({
          boxLength: _.isEmpty(box.boxLength) ? "Box length is required." : "",
          boxWidth: _.isEmpty(box.boxWidth) ? "Box width is required." : "",
          boxHeight: _.isEmpty(box.boxHeight) ? "Box height is required." : "",
          boxPounds: _.isEmpty(box.boxPounds) ? "Box pounds is required." : "",
          boxOunces: _.isEmpty(box.boxOunces) ? "Box ounces is required." : "",
        })
      } else if (combinedWeight > 70 * 16) {
        // 70 pounds * 16 ounces = 1120 ounces
        // USPS only allows a maximum of 70 pounds or 1120 ounces
        errors.push({
          boxPounds: "Combined weight cannot exceed 70 pounds.",
          boxOunces: "Combined weight cannot exceed 70 pounds.",
        })
      } else {
        // Push an empty object to the errors array
        errors.push({})
      }
    })

    Object.keys(boxAddress).forEach((value, firstIndex) => {
      Object.values(boxAddress[value]).forEach((value, index) => {
        // See if were are in the from or to object
        const type = Object.keys(boxAddress)[firstIndex]

        // Get the value that is empty
        const emptyValue = Object.keys(boxAddress[type])[index]

        // Edit the emptyValue so that it is readable
        const readableEmptyValue = emptyValue
          .split(/(?=[A-Z]+|[0-9])/)
          // Uppercase the first letter of each word
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")

        // If the value is empty and not the address line 2
        if (_.isEmpty(value) && emptyValue !== "addressLine2") {
          zipValidate = true
          boxAddressErrors[type][
            emptyValue
          ] = `${readableEmptyValue} is required.`
        }
      })
    })

    // Check if the errors array contains any empty object
    let isFormValid = errors.some((error) => {
      return Object.keys(error).length !== 0
    })

    let isBoxAddressFormValid =
      Object.keys(boxAddressErrors.from).length !== 0 ||
      Object.keys(boxAddressErrors.to).length !== 0

    this.setState({
      boxFormError: errors,
      boxAddressError: boxAddressErrors,
    })

    // Call handleVerifyAddress for 'from' and 'to' addresses if no form errors
    if (!isFormValid && !isBoxAddressFormValid) {
      this.handleVerifyAddress()
    }

    // Set the state
    if (isFormValid || isBoxAddressFormValid) {
      return true
    }

    return false
  }

  handleUSPSSubmit = (e) => {
    e.preventDefault()
    if (!this.handleUSPSValidate()) {
      this.handleGetUSPSRate()
    }
  }

  handleAddBox = () => {
    const { boxList } = this.state

    // Add a new box to the boxList
    boxList.push({
      id: boxList.length,
      selectedRate: null,
      boxLength: "",
      boxWidth: "",
      boxHeight: "",
      boxPounds: "",
      boxOunces: "",
    })

    this.setState({
      boxList,
      uspsBoxRateConfirm: false,
    })
  }

  clearUSPSData = () => {
    this.setState({
      // Reset USPS Boxes
      boxList: [
        {
          id: 0,
          selectedRate: null,
          boxLength: "",
          boxWidth: "",
          boxHeight: "",
          boxPounds: "",
          boxOunces: "",
        },
      ],
      ratesByBox: {},
      boxAddressError: [],
      boxFormError: [],
      uspsLabelOrderInfo: [],
      uspsErrors: [],
      showUSPSModal: false,
      showUSPSAddressModal: false,
      showUSPSLabelModal: false,
      uspsLabelsPrinted: false,
      uspsCreateLabelError: false,
      destinationEntryFacilityAddress: null,
      destinationEntryFacilityType: null,
      destinationEntryFacilities: [],
      selectedFacility: null,
    })
  }

  handleRemoveBox = (index) => {
    const { boxList, uspsBoxRates, uspsBoxTotals, ratesByBox } = this.state

    // Remove the box from the boxList based on the index
    const removedBox = boxList.splice(index, 1)[0]

    // Remove the rate associated with the removed box
    delete uspsBoxRates[removedBox.id]

    delete ratesByBox[removedBox.id]
    // Update the uspsBoxTotals by subtracting the price of the removed box
    const updatedBoxTotals = removedBox.selectedRate
      ? uspsBoxTotals - parseFloat(removedBox.selectedRate.price)
      : uspsBoxTotals

    this.setState({
      boxList,
      uspsBoxRates,
      uspsBoxTotals:
        updatedBoxTotals <= 0 ? 0 : parseFloat(updatedBoxTotals).toFixed(2),
    })
  }

  handleBoxInputChange = (e, index) => {
    const { boxList } = this.state

    // Update the boxList based on the index
    boxList[index][e.target.name] = e.target.value

    this.setState({
      boxList,
      uspsBoxRateConfirm: false,
      ratesByBox: {},
      uspsBoxRates: {},
    })
  }

  handleShippingAddressChanged = (e) => {
    const { boxAddress } = this.state

    // Split the name and get the index
    let [index, value] = e.target.name.split(".")

    // Update the boxAddress based on the name and if it's "from" or "to"
    boxAddress[index][value] = e.target.value

    this.setState({
      uspsBoxRateConfirm: false,
      boxAddress,
      ratesByBox: {},
      uspsBoxRates: {},
    })
  }

  handleGetUSPSRate = () => {
    const { boxList, boxAddress, farmId } = this.state
    const { dispatch } = this.props

    const params = {
      boxes: boxList,
      postalCodeOrigin: boxAddress.from.postalCode,
      postalCodeDestination: boxAddress.to.postalCode,
      farmId,
    }

    dispatch(farmActions.domesticRate(params)).then((data) => {
      //This method can be simplified it works but is based off of when the API return was more complex.
      if (data.statusCode === 1) {
        const ratesByBox = {}

        const rateDescriptions = {
          USPS_CONNECT_LOCAL:
            "Connect Local - Same-day and next-day delivery * Note: Must deliver package to a Post Office located in the same Zip Code as the delivery Zip Code",
          USPS_GROUND_ADVANTAGE:
            "Ground Advantage - 2 - 5 business days, $100 insurance included",
          PRIORITY_MAIL:
            "Priority Mail - 1 - 3 business days, $100 insurance included",
          PRIORITY_MAIL_EXPRESS:
            "Priority Express - next day - 2 business days, $100 insurance included",
        }

        data.responseData.forEach((item, index) => {
          const filteredRates = {}
          item.rateOptions.forEach((option) => {
            const rate = option
            let rateType

            //For some reason connect local mail class prices returns as parcel select?
            if (
              (rate.mailClass === "USPS_CONNECT_LOCAL" ||
                rate.mailClass === "PARCEL_SELECT") &&
              rate.description.includes("Connect Local")
            ) {
              rateType = "USPS_CONNECT_LOCAL"
            } else if (
              rate.mailClass === "USPS_GROUND_ADVANTAGE" &&
              rate.description.includes("Single-piece")
            ) {
              rateType = "USPS_GROUND_ADVANTAGE"
            } else if (
              rate.mailClass === "PRIORITY_MAIL" &&
              rate.description.includes("Single-piece")
            ) {
              rateType = "PRIORITY_MAIL"
            } else if (
              rate.mailClass === "PRIORITY_MAIL_EXPRESS" &&
              rate.description.includes("Single-piece")
            ) {
              rateType = "PRIORITY_MAIL_EXPRESS"
            }

            if (rateType && !filteredRates[rateType]) {
              filteredRates[rateType] = {
                ...rate,
                description: rateDescriptions[rateType],
              }
            }
          })

          ratesByBox[boxList[index].id] = Object.values(filteredRates)
        })

        this.setState({
          ratesByBox,
          loader: false,
        })
      } else {
        this.setState({
          loader: false,
          reasonErMsg: data.error.responseMessage,
        })
      }
    })
  }

  renderRateOptions = (box) => {
    const { ratesByBox } = this.state
    const rates = ratesByBox[box.id] || []

    if (rates.length > 0) {
      return (
        <select
          value={box.selectedRate ? box.selectedRate.SKU : ""}
          onChange={(e) => {
            const selectedRate = rates.find(
              (rate) => rate.SKU === e.target.value
            )
            this.handleSelectRate(box, selectedRate)
          }}
        >
          <option value="">Select a rate</option>
          {rates.map((rate, index) => (
            <option key={index} value={rate.SKU}>
              {rate.description} - ${rate.price.toFixed(2)}
            </option>
          ))}
        </select>
      )
    }
  }

  handleSelectRate = (box, rate) => {
    this.setState(
      (prevState) => ({
        boxList: prevState.boxList.map((b) =>
          b.id === box.id ? { ...b, selectedRate: rate || null } : b
        ),
      }),
      () => {
        if (rate && rate.description.includes("Connect Local")) {
          this.handleEntryFacilities()
        }
      }
    )
  }

  handleEntryFacilities = () => {
    const { farmId, boxAddress } = this.state
    const { dispatch } = this.props
    const params = { farmId, destinationZIPCode: boxAddress.to.postalCode }

    this.setState({ loadingPickupLocations: true })

    dispatch(farmActions.entryFacilities(params))
      .then((response) => {
        this.setState({
          destinationEntryFacilities: response.responseData,
          loadingPickupLocations: false,
        })
      })
      .catch((error) => {
        console.error("Error fetching entry facilities:", error)
        this.setState({ loadingPickupLocations: false })
      })
  }

  handleFacilityChange = (event) => {
    const selectedFacility = this.state.destinationEntryFacilities.find(
      (facility) => facility.dropShipmentKey === event.target.value
    )
    if (selectedFacility) {
      const address = selectedFacility.facilityAddress
      this.setState({
        selectedFacility,
        destinationEntryFacilityAddress: address,
        destinationEntryFacilityType:
          selectedFacility.destinationEntryFacilityType,
      })
    } else {
      this.setState({
        selectedFacility,
        destinationEntryFacilityAddress: null,
        destinationEntryFacilityType: null,
      })
    }
  }

  handleShowUSPSConfirmModal = () => {
    this.setState({
      showUSPSModal: false,
      showUSPSLabelModal: true,
    })
  }

  handleCreateLabels = () => {
    const { boxList, boxAddress, uspsLabelOrderInfo } = this.state
    const { dispatch } = this.props

    if (this.state.isCreatingLabels) {
      return // Exit if labels are already being created
    }

    this.setState({ isCreatingLabels: true }) // Disable the button

    const params = {
      orderNumber: uspsLabelOrderInfo[0]._id,
      farmId: this.state.farmId,
      farmStripeId: this.state.farmStripeId,
      boxes: boxList,
      farm: {
        addressLine1: boxAddress.from.addressLine1.trim(),
        addressLine2: boxAddress.from.addressLine2.trim(),
        city: boxAddress.from.city.trim(),
        state: boxAddress.from.state.trim(),
        postalCode: boxAddress.from.postalCode.trim(),
        phone: this.state.phone,
        name: this.state.farmName,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      },
      customer: {
        addressLine1: boxAddress.to.addressLine1.trim(),
        addressLine2: boxAddress.to.addressLine2.trim(),
        city: boxAddress.to.city.trim(),
        state: boxAddress.to.state.trim(),
        postalCode: boxAddress.to.postalCode.trim(),
        phone: uspsLabelOrderInfo[0].billingInfo.phoneNumber,
        name: uspsLabelOrderInfo[0].customer,
        customerRefNo2: uspsLabelOrderInfo[0].customerId,
      },
      packageDescription: {
        destinationEntryFacilityAddress:
          this.state.destinationEntryFacilityAddress,
        destinationEntryFacilityType: this.state.destinationEntryFacilityType,
      },
    }

    dispatch(farmActions.domesticPackageLabel(params)).then((data) => {
      if (data.statusCode === 1) {
        this.setState({ uspsLabelsPrinted: true })
        data.responseData.forEach((label) => {
          if (label.labelImage.data) {
            this.bufferToBase64(label.labelImage.data).then((base64String) =>
              this.decodeBase64Pdf(base64String, true)
            )
          } else {
            this.decodeBase64Pdf(label.labelImage, true)
          }
        })
        this.getOrderList()
      } else {
        this.setState({
          loader: false,
          reasonErMsg: "",
          errors: data.error.responseMessage,
          uspsCreateLabelError: data.error.errors ? true : false,
          uspsErrors: data.error.errors
            ? {
                labelError:
                  "An error occurred while creating the label. Please ensure the Ship From and Ship To values are correct.",
              }
            : {},
        })
      }
      this.setState({ isCreatingLabels: false }) // Re-enable the button after completion
    })
  }

  handleLabelFinish = () => {
    this.clearUSPSData()

    // Update the order list
    // displaying the new label
    this.getOrderList()
  }

  handleItemMultiSelect = (e, val, ItemList, io) => {
    let { multiItemValue } = this.state
    this.setState({ reasonErMsg: "" })
    multiItemValue = []

    if (this.state.itemId !== undefined && this.state.itemId.includes(val)) {
      for (var i = 0; i < this.state.itemId.length; i++) {
        if (this.state.itemId[i] === val) {
          this.state.itemId.splice(i, 1)
        }
      }
    } else {
      this.state.itemId.push(val)
    }

    ItemList.map((value) => {
      if (value.productId === val) {
        if (value.itemStatus === 2) {
          multiItemValue.push(value._id)
        } else if (value.itemStatus === 3) {
          multiItemValue.splice(value._id)
        } else if (value.itemStatus === 0) {
          multiItemValue.push(value._id)
        }
      }
    })

    this.setState({ multiItemValue: [...this.state.multiItemValue, val] })
  }

  handleItemAction = (id, harvestStatus) => {
    let unique = [...new Set(this.state.multiItemValue)]

    let params = {
      orderId: id,
      itemId: unique,
      itemStatus: harvestStatus,
    }

    let self = this

    let { dispatch } = this.props
    self.setState({ loader: true, show: false })
    dispatch(orderActions.changeOrderItemStatus(params)).then((data) => {
      if (data.statusCode === 1) {
        self.setState({
          loader: false,
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
        })
        //this.notify("success", data.responseData.message)
        this.getOrderList()
      } else {
        self.setState({ loader: false })
        this.setState({
          itemId: [],
          multiValue: "",
          reasonErMsg: "",
          errors: data.error.responseMessage,
        })
      }
    })
  }

  renderOrderStatus = (status) => {
    const badgeConfig = {
      "New Order": "success",
      Fulfilled: "info",
      "In Harvest List": "warning",
    }

    const displayStatus = HARVEST_STATUSES[status]

    return <Badge variant={badgeConfig[displayStatus]}>{displayStatus}</Badge>
  }

  render() {
    let {
      filterHarvest,
      reasonErMsg,
      orderInfo,
      count,
      pageNo,
      ItemList,
      userId,
      farmStripeId,
      uspsLabelOrderInfo,
      uspsErrors,
      boxList,
      boxFormError,
      uspsBoxRates,
      uspsBoxTotals,
      uspsBoxRateConfirm,
      boxAddressError,
      uspsLabelsPrinted,
      uspsCreateLabelError,
      destinationEntryFacilities,
      selectedFacility,
      ratesByBox,
    } = this.state

    // Check if any box doesn't have rates
    const boxesWithoutRates = boxList.some((box) => !ratesByBox[box.id])

    // Check if any box has a "Connect Local" rate
    const showFacilitySelect = boxList.some((box) =>
      box.selectedRate?.description.includes("Connect Local")
    )

    // Ensure all boxes have selected rates and, if needed, a destinationEntryFacility is selected
    const canContinue =
      boxList.every((box) => box.selectedRate !== null) &&
      (!showFacilitySelect || selectedFacility !== null)

    return (
      <>
        <Sidebar />
        <div className="body_container">
          <div className="body_inner_wrapper">
            {this.state.loader ? (
              <div className="loader_wrapper">
                <div className="spinner-border text-info" role="status"></div>
              </div>
            ) : (
              <>
                <header className="header_content orders-header">
                  <h1 className="orders-header-heading h6">
                    {/* show heading ass appropriate */}
                    {filterHarvest === ORDER_HARVEST_STATUS.ALL && "All Orders"}
                    {filterHarvest === ORDER_HARVEST_STATUS.HARVEST &&
                      "Harvest List"}
                    {filterHarvest === ORDER_HARVEST_STATUS.FULFILLED &&
                      "Fulfilled Orders"}
                  </h1>
                  <div className="d-flex text-nowrap header_btn-broken orders-header-buttons">
                    <Button
                      variant="primary"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() =>
                        this.handleAction(ORDER_HARVEST_STATUS.HARVEST)
                      }
                    >
                      {" "}
                      Add to Harvest List
                    </Button>
                    <Button
                      variant="secondary"
                      disabled={
                        _.isEmpty(this.state.multiValue) ||
                        this.state.multiValue.length > 1
                          ? true
                          : false
                      }
                      onClick={() => this.handleUSPSAction()}
                    >
                      Print USPS Label
                    </Button>
                    <Button
                      variant="info"
                      disabled={_.isEmpty(this.state.orderInfo) ? true : false}
                      onClick={() =>
                        this.handleAction(ORDER_HARVEST_STATUS.FULFILLED)
                      }
                    >
                      {" "}
                      Mark Order Fulfilled
                    </Button>
                    <DropdownButton
                      as={ButtonGroup}
                      id="harvest-list-options"
                      title="View Harvest List"
                      variant="warning"
                    >
                      <Dropdown.Item
                        onClick={() =>
                          this.handleFilter(ORDER_HARVEST_STATUS.HARVEST)
                        }
                      >
                        {" "}
                        In List Below
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={this.getOrderExcel.bind(
                          this,
                          ORDER_HARVEST_STATUS.HARVEST
                        )}
                      >
                        {" "}
                        Export As Excel
                      </Dropdown.Item>
                    </DropdownButton>
                    <Button
                      variant="info"
                      onClick={() =>
                        this.handleFilter(ORDER_HARVEST_STATUS.FULFILLED)
                      }
                    >
                      {" "}
                      View Fulfilled Orders
                    </Button>
                    <Button
                      variant="success"
                      onClick={() =>
                        this.handleFilter(ORDER_HARVEST_STATUS.ALL)
                      }
                    >
                      {" "}
                      View All Orders
                    </Button>
                    <Button
                      onClick={this.getOrderExcel.bind(
                        this,
                        ORDER_HARVEST_STATUS.ALL
                      )}
                      variant="info"
                    >
                      <i className="fas fa-upload"></i> Export All Orders as
                      Excel
                    </Button>
                  </div>
                </header>
                {/* all orders */}
                {(filterHarvest === ORDER_HARVEST_STATUS.ALL ||
                  filterHarvest === "") && (
                  <div className="main_content">
                    <div className="table-responsive">
                      <table className="table cm_bottom_space">
                        <thead>
                          <tr className="border_top_0">
                            <th>Change Status</th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Total
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Distributions
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Day & Time
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Status
                            </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo &&
                            orderInfo.map((item, i) => (
                              <tr key={item._id}>
                                <td>
                                  <div
                                    className={`cm_radio_3_wrapper`}
                                    style={{ background: "#fff" }}
                                  >
                                    <input
                                      type="checkbox"
                                      indeterminate="true"
                                      value={item.status}
                                      onChange={(e) =>
                                        this.handleMultiSelect(
                                          e,
                                          item._id && item._id
                                        )
                                      }
                                    />
                                    <span className="cm_radio_3"></span>
                                  </div>
                                </td>
                                <td>{item.placedOn}</td>
                                <td>{item.customer}</td>
                                <td>{item.total}</td>
                                <td>
                                  {orderDistributions({
                                    order: item,
                                    farmUserId: userId,
                                  }).map((distributionString) => {
                                    return (
                                      <p className="mb0">
                                        {distributionString}
                                      </p>
                                    )
                                  })}
                                </td>
                                <td>
                                  {orderTimeString({
                                    order: item,
                                    farmUserId: userId,
                                  })}
                                </td>
                                <td>{this.renderOrderStatus(item.status)}</td>
                                <td>
                                  <div className="action_wrapper ws-nowrap">
                                    <span>
                                      {" "}
                                      <i
                                        className="fas fa-eye"
                                        onClick={() => this.handleShow(item)}
                                      ></i>
                                    </span>

                                    <span>
                                      <Link to={`vieworder/${item._id}`}>
                                        <i
                                          className="fas fa-save"
                                          title="Invoice"
                                        ></i>
                                      </Link>
                                    </span>

                                    <span
                                      title="Add to harvest"
                                      onClick={() =>
                                        this.ChangeHarvestStatus(
                                          item._id,
                                          ORDER_HARVEST_STATUS.HARVEST
                                        )
                                      }
                                      className="icon-sickle"
                                    ></span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {this.state.totalCount > this.state.count && (
                        <div className="d-flex justify-content-end cm-pagination pagination-all-orders">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.count}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      )}
                      {_.isEmpty(this.state.orderInfo) &&
                        this.state.hasOrderInfo && (
                          <h3 className="text-center">No data found</h3>
                        )}
                      {reasonErMsg && (
                        <div className="text-center error ">{reasonErMsg}</div>
                      )}
                    </div>
                  </div>
                )}
                {/* harvest list */}
                {/* setup harvest list as a new component, so it could also be used within the admin, if needed */}
                {filterHarvest === ORDER_HARVEST_STATUS.HARVEST && (
                  <div className="main_content">
                    <HarvestList
                      orderInfo={orderInfo}
                      farmUserId={localStorage.getItem("userId")}
                    />
                  </div>
                )}
                {/* fulfilled orders */}
                {filterHarvest === ORDER_HARVEST_STATUS.FULFILLED && (
                  <div className="main_content">
                    <div className="table-responsive">
                      <table className="table cm_bottom_space">
                        <thead>
                          <tr className="broder_top_0">
                            <th>Change Status</th>
                            <th scope="col">Placed On</th>
                            <th scope="col" className="ws-nowrap">
                              Customer
                            </th>
                            <th scope="col" className="ws-nowrap">
                              Item
                            </th>
                            <th scope="col" className="ws-nowrap">
                              {" "}
                              Quantity
                            </th>
                            <th scope="col">Price</th>
                            <th scope="col" className="ws-nowrap">
                              Distributions
                            </th>

                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!_.isEmpty(orderInfo) &&
                            orderInfo.map((item, i) => {
                              const harvestStatus = item.buyList[0]
                                .harvestStatus
                                ? +item.buyList[0].harvestStatus
                                : -1
                              return (
                                <tr key={item._id}>
                                  <td>
                                    <div
                                      className={`cm_radio_3_wrapper`}
                                      style={{ background: "#fff" }}
                                    >
                                      <input
                                        type="checkbox"
                                        indeterminate
                                        value={item.status}
                                        onChange={(e) =>
                                          this.handleMultiSelect(
                                            e,
                                            item._id && item._id
                                          )
                                        }
                                      />
                                      <span className="cm_radio_3"></span>
                                    </div>
                                  </td>
                                  <td>{item.placedOn}</td>
                                  <td>{item.customer}</td>
                                  {/*{item}*/}
                                  <td> {this.OrderHarvest1(item)}</td>
                                  {/*{unit}*/}
                                  <td> {this.OrderHarvest2(item)}</td>
                                  {/*{price}*/}
                                  <td> {this.OrderHarvest3(item)}</td>
                                  <td>
                                    {orderDistributions({
                                      order: item,
                                      farmUserId: userId,
                                    }).map((distributionString) => {
                                      return (
                                        <p className="dark m0">
                                          {distributionString}
                                        </p>
                                      )
                                    })}
                                  </td>
                                  <td>
                                    {harvestStatus === 0 && (
                                      <Badge variant="success">New Order</Badge>
                                    )}
                                    {harvestStatus === 1 && (
                                      <Badge variant="warning">
                                        In Harvest List
                                      </Badge>
                                    )}
                                    {harvestStatus === 2 && (
                                      <Badge variant="info">FulFilled</Badge>
                                    )}
                                    {harvestStatus == 3 ? "Out of Stock" : ""}
                                  </td>
                                  <td>
                                    <div className="action_wrapper1 ws-nowrap">
                                      <span
                                        onClick={() => this.handleShow(item)}
                                      >
                                        <i
                                          className="fas fa-eye"
                                          title="Order Details"
                                        ></i>
                                      </span>

                                      <span>
                                        <Link to={`vieworder/${item._id}`}>
                                          <i
                                            className="fas fa-save"
                                            title="Invoicddddde"
                                          ></i>
                                        </Link>
                                      </span>
                                      <span
                                        title="Add to harvest"
                                        onClick={() =>
                                          this.ChangeHarvestStatus(
                                            item._id,
                                            ORDER_HARVEST_STATUS.HARVEST
                                          )
                                        }
                                        className="icon-sickle"
                                      ></span>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                      {this.state.totalCount > this.state.count && (
                        <div className="d-flex justify-content-end cm-pagination pagination-fulfilled-orders">
                          <Pagination
                            itemClassPrev={"prevClass"}
                            itemClassNext={"nextClass"}
                            activePage={this.state.pageNo}
                            itemsCountPerPage={this.state.dataCount}
                            totalItemsCount={this.state.totalCount}
                            pageRangeDisplayed={3}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      )}
                      {_.isEmpty(this.state.orderInfo) &&
                        this.state.hasOrderInfo && (
                          <h3 className="text-center">No data found</h3>
                        )}
                      {reasonErMsg && (
                        <div className="text-center error ">{reasonErMsg}</div>
                      )}
                    </div>
                  </div>
                )}{" "}
              </>
            )}
          </div>

          <Modal
            aria-label="example-modal-sizes-title-sm "
            show={this.state.show}
            onHide={this.handleClose}
            className="cm_order_view order-detailsmodal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                <h6>Order Details</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id={"divIdToPrint1"} className="">
                <Row>
                  <Col md="12">
                    {ItemList.customer && (
                      <p>
                        Name: <span>{ItemList.customer}</span>
                      </p>
                    )}

                    {ItemList.billingInfo &&
                      ItemList.billingInfo.phoneNumber && (
                        <p>
                          Phone:{" "}
                          <span>{ItemList.billingInfo.phoneNumber} </span>
                        </p>
                      )}

                    {ItemList && ItemList.status !== undefined && (
                      <p>
                        Status: <span>{HARVEST_STATUSES[ItemList.status]}</span>
                      </p>
                    )}

                    {ItemList.billingInfo && ItemList.billingInfo && (
                      <p>
                        Address:{" "}
                        <span>
                          {ItemList.billingInfo.addressLine1}{" "}
                          {ItemList.billingInfo.addressLine2}{" "}
                          {ItemList.billingInfo.city}{" "}
                          {ItemList.billingInfo.state}{" "}
                          {ItemList.billingInfo.country}{" "}
                          {ItemList.billingInfo.postalCode}
                        </span>
                      </p>
                    )}

                    <p>
                      Distributions:
                      {orderDistributions({
                        order: ItemList,
                        farmUserId: userId,
                      }).map((distributionString) => {
                        return <p className="dark m0">{distributionString}</p>
                      })}
                    </p>
                    <p>
                      Farm Name: <span>{this.state.farmName}</span>
                    </p>
                    {ItemList &&
                      ItemList.buyList &&
                      ItemList.buyList.map((val, i) =>
                        val.trackingInfo && val.trackingInfo.length > 0 ? (
                          <div className="usps_border_top" key={i}>
                            {val.trackingInfo.map((box, index) => (
                              <div
                                className="d-flex align-items-center mb-2"
                                key={index}
                              >
                                <p className="mb-0 mr-2">
                                  USPS Package {index + 1}:
                                </p>
                                <a
                                  href={`https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${box.trackingNumber}`} // Update this for the list
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Button
                                    variant="link"
                                    className="usps_order_button mr-2"
                                  >
                                    Track USPS Package
                                  </Button>
                                </a>
                                <Button
                                  onClick={() => {
                                    this.decodeBase64Pdf(box.labelImage)
                                  }}
                                  variant="light"
                                  className="usps_order_button"
                                >
                                  Download USPS Label
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.handleCancelLabel(box.trackingNumber)
                                  }
                                  variant="danger"
                                  className="usps_order_button"
                                >
                                  Cancel USPS Label
                                </Button>
                              </div>
                            ))}
                          </div>
                        ) : null
                      )}
                  </Col>
                </Row>
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ws-nowrap">Product Name</th>
                          <th className="ws-nowrap">Variety</th>
                          <th className="ws-nowrap">Product Price</th>
                          <th className="ws-nowrap">Distribution</th>
                          <th className="ws-nowrap">Product Image</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetailsTable({
                          order: ItemList,
                          farmUserId: userId,
                        }).map(
                          ({ tableRowValues, productListEntry }, index) => {
                            return (
                              <tr key={`order-buy-mp-${index}`}>
                                <td>
                                  <p>{tableRowValues.productName}</p>
                                  <SubscriptionInfo
                                    inventoryItem={productListEntry}
                                  />
                                </td>
                                <td>{tableRowValues.variety}</td>
                                <td>
                                  <span>{tableRowValues.price}</span>
                                </td>
                                <td>{tableRowValues.distributionString}</td>
                                <td>
                                  {tableRowValues.image && (
                                    <img
                                      alt=""
                                      src={tableRowValues.image}
                                      height="40px"
                                      width="40px"
                                    />
                                  )}
                                </td>
                              </tr>
                            )
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="">
                  <p>
                    Order Total:
                    <span>${this.sumOrder(ItemList && ItemList.buyList)}</span>
                  </p>

                  <p>
                    Delivery Charges:
                    <span>
                      ${this.sumDelivery(ItemList && ItemList.buyList)}
                    </span>
                  </p>

                  <p>
                    Total:
                    <span>
                      {" "}
                      $
                      {sumOrderAndDelivery(
                        ItemList && ItemList.buyList,
                        this.state.userId
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="print_button orderabs">
                <Link
                  className="btn btn-light "
                  to={`vieworder/${ItemList._id}`}
                >
                  <i className="fas fa-print" title="Invoicddddde"></i>Print
                </Link>
              </div>
            </Modal.Footer>
          </Modal>

          <USPSLoginModal
            show={this.state.showUSPSLoginModal}
            onHide={() => this.setState({ showUSPSLoginModal: false })}
            farmId={this.state.farmId}
          />

          {/* USPS Modal */}
          <Modal
            show={this.state.showUSPSModal}
            onHide={() => {
              this.clearUSPSData()
            }}
            className="cm_order_view dlt_item dlt_item__usps"
          >
            <form onSubmit={this.handleUSPSSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>USPS Box Rates</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row className="usps_border_bottom">
                  <Form.Group as={Col}>
                    <Form.Label className="usps_form_label">
                      Ship From
                    </Form.Label>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 1"
                          onChange={this.handleShippingAddressChanged}
                          name="from.addressLine1"
                          value={
                            this.state.boxAddress.from.addressLine1
                              ? this.state.boxAddress.from.addressLine1
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.addressLine1 ? (
                          <span className="error_msg">
                            {boxAddressError.from.addressLine1}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 2"
                          onChange={this.handleShippingAddressChanged}
                          name="from.addressLine2"
                          value={
                            this.state.boxAddress.from.addressLine2
                              ? this.state.boxAddress.from.addressLine2
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.addressLine2 ? (
                          <span className="error_msg">
                            {boxAddressError.from.addressLine2}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          onChange={this.handleShippingAddressChanged}
                          name="from.city"
                          value={
                            this.state.boxAddress.from.city
                              ? this.state.boxAddress.from.city
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.city ? (
                          <span className="error_msg">
                            {boxAddressError.from.city}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          onChange={this.handleShippingAddressChanged}
                          name="from.state"
                          value={
                            this.state.boxAddress.from.state
                              ? this.state.boxAddress.from.state
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.state ? (
                          <span className="error_msg">
                            {boxAddressError.from.state}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Postal Code"
                          onChange={this.handleShippingAddressChanged}
                          name="from.postalCode"
                          value={
                            this.state.boxAddress.from.postalCode
                              ? this.state.boxAddress.from.postalCode
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.from &&
                        boxAddressError.from.postalCode ? (
                          <span className="error_msg">
                            {boxAddressError.from.postalCode}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="usps_border_bottom">
                  <Form.Group as={Col}>
                    <Form.Label className="usps_form_label">Ship To</Form.Label>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 1"
                          onChange={this.handleShippingAddressChanged}
                          name="to.addressLine1"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.addressLine1
                              ? this.state.boxAddress.to.addressLine1
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.addressLine1 ? (
                          <span className="error_msg">
                            {boxAddressError.to.addressLine1}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address 2"
                          onChange={this.handleShippingAddressChanged}
                          name="to.addressLine2"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.addressLine2
                              ? this.state.boxAddress.to.addressLine2
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.addressLine2 ? (
                          <span className="error_msg">
                            {boxAddressError.to.addressLine2}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="City"
                          onChange={this.handleShippingAddressChanged}
                          name="to.city"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.city
                              ? this.state.boxAddress.to.city
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.city ? (
                          <span className="error_msg">
                            {boxAddressError.to.city}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          onChange={this.handleShippingAddressChanged}
                          name="to.state"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.state
                              ? this.state.boxAddress.to.state
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.state ? (
                          <span className="error_msg">
                            {boxAddressError.to.state}
                          </span>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Postal Code"
                          onChange={this.handleShippingAddressChanged}
                          name="to.postalCode"
                          value={
                            this.state.boxAddress.to &&
                            this.state.boxAddress.to.postalCode
                              ? this.state.boxAddress.to.postalCode
                              : ""
                          }
                        />
                        {boxAddressError &&
                        boxAddressError.to &&
                        boxAddressError.to.postalCode ? (
                          <span className="error_msg">
                            {boxAddressError.to.postalCode}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                  </Form.Group>
                </Form.Row>
                <span className="error_msg">
                  {uspsErrors["fromAddressError"]}
                </span>
                <span className="error_msg">
                  {uspsErrors["toAddressError"]}
                </span>
                <div>
                  {uspsLabelOrderInfo.map((item, orderIndex) => (
                    <div key={orderIndex}>
                      <div className="usps_flex_start">
                        <p>Customer: {item.customer}</p>
                        <p>Order Total: {item.total}</p>
                      </div>
                      {boxList.length > 0 &&
                        boxList.map((box, boxIndex) => (
                          <div key={boxIndex}>
                            <USPSBoxForm
                              errors={boxFormError}
                              box={box}
                              index={boxIndex}
                              boxList={boxList}
                              handleRemoveBox={this.handleRemoveBox}
                              handleBoxInputChange={this.handleBoxInputChange}
                              uspsBoxRates={this.state.uspsBoxRates}
                              ratesByBox={this.state.ratesByBox}
                              handleSelectRate={this.handleSelectRate}
                            />
                          </div>
                        ))}
                      {boxList.length < 25 && (
                        <Form.Group className="usps_form_group">
                          <Button
                            variant="outline-primary"
                            onClick={this.handleAddBox}
                          >
                            Add Box
                          </Button>
                        </Form.Group>
                      )}
                    </div>
                  ))}
                  {showFacilitySelect && (
                    <Form.Group>
                      <Form.Label>Select Entry Facility</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={this.handleFacilityChange}
                        value={
                          this.state.selectedFacility?.dropShipmentKey || ""
                        }
                      >
                        <option value="">Select a drop off location</option>
                        {this.state.destinationEntryFacilities.map(
                          (facility) => (
                            <option
                              key={facility.dropShipmentKey}
                              value={facility.dropShipmentKey}
                            >
                              {facility.facilityName} - {facility.facilityName}{" "}
                              - {facility.facilityAddress.streetAddress},{" "}
                              {facility.facilityAddress.city},{" "}
                              {facility.facilityAddress.state}{" "}
                              {facility.facilityAddress.ZIPCode}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="footer_btn_wrapper">
                  {boxesWithoutRates ? (
                    <Button type="submit">Get Box Rates</Button>
                  ) : (
                    <Button
                      variant="warning"
                      onClick={this.handleShowUSPSConfirmModal}
                      disabled={!canContinue}
                    >
                      Continue
                    </Button>
                  )}
                  <Button
                    variant="dark"
                    onClick={() =>
                      this.setState({
                        boxFormError: [],
                        boxAddressError: [],
                        showUSPSModal: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </form>
          </Modal>

          {/* USPS Label */}
          <Modal
            show={this.state.showUSPSLabelModal}
            onHide={() => this.clearUSPSData()}
            className="cm_order_view dlt_item dlt_item__usps"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Create USPS Labels</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="usps_labels">
                <div className="order_items">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="ws-nowrap">Box</th>
                          <th className="ws-nowrap">Weight</th>
                          <th className="ws-nowrap">Length</th>
                          <th className="ws-nowrap">Width</th>
                          <th className="ws-nowrap">Height</th>
                          <th className="ws-nowrap">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {boxList.map((box, index) => {
                          return (
                            <>
                              <tr key={`order-buy-mp-${index}`}>
                                <td>{index + 1}</td>
                                <td>
                                  {box.boxPounds} lbs, {box.boxOunces} oz
                                </td>
                                <td>{box.boxLength}</td>
                                <td>{box.boxWidth}</td>
                                <td>{box.boxHeight}</td>
                                <td>{box.selectedRate?.price}</td>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <span className="error_msg">{uspsErrors["labelError"]}</span>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="footer_btn_wrapper">
                {uspsLabelsPrinted ? (
                  <Button
                    variant="warning"
                    onClick={() => {
                      this.handleLabelFinish()
                    }}
                  >
                    Finish
                  </Button>
                ) : (
                  <>
                    {!uspsCreateLabelError ? (
                      <Button
                        variant="warning"
                        type="submit"
                        onClick={() => {
                          this.handleCreateLabels()
                        }}
                        disabled={this.state.isCreatingLabels}
                      >
                        Create Labels
                      </Button>
                    ) : (
                      <Button
                        variant="warning"
                        onClick={() => {
                          this.setState({
                            showUSPSLabelModal: false,
                            showUSPSModal: true,
                            uspsCreateLabelError: false,
                            uspsErrors: {},
                          })
                        }}
                      >
                        Back
                      </Button>
                    )}

                    <Button
                      variant="dark"
                      onClick={() =>
                        this.setState({
                          // multiValue: [],
                          uspsLabelsPrinted: false,
                          uspsBoxRateConfirm: false,
                          uspsCreateLabelError: false,

                          // USPS Boxes
                          boxList: [
                            {
                              id: 0,
                              selectedRate: null,
                              boxLength: "",
                              boxWidth: "",
                              boxHeight: "",
                              boxPounds: "",
                              boxOunces: "",
                            },
                          ],
                          boxAddressError: [],
                          boxFormError: [],
                          ratesByBox: {},

                          // USPS Label
                          showUSPSLabelModal: false,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  const { props, login, user, mapReducer } = state
  return {
    props,
    login,
    user,
    mapReducer,
  }
}
export default connect(mapStateToProps)(Order)
